<template>
	<div id="ftc-san-edit" :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="businessRecord">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">{{ businessRecord.id ? 'Edit' : 'Create' }} Business Record</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" v-if="!isDisabled"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()" v-if="!isDisabled"
						>delete</p-icon
					>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<div class="column-format gap-3 mt-2 text-left">
				<h3>Business information</h3>
				<div>Business Record Id: {{businessRecord.id}}</div>
				<div class="row-format gap-2">
					<v-text-field
						label="Legal name"
						v-model="businessRecord.legalName"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="EIN"
						v-model="businessRecord.ein"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
				<div class="row-format gap-2">
					<v-text-field
						label="Website"
						v-model="businessRecord.website"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="Corporate phone"
						v-model="businessRecord.phone"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
				<div class="row-format gap-2">
					<v-text-field
						label="Address 1"
						v-model="businessRecord.address.address1"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="Address 2"
						v-model="businessRecord.address.address2"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
				<div class="row-format gap-2">
					<v-text-field
						label="City"
						v-model="businessRecord.address.city"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="State"
						v-model="businessRecord.address.state"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="Zip"
						v-model="businessRecord.address.zip"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
				<v-select
					label="Status"
					:items="statusList"
					v-model="businessRecord.resourceStatus"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
					:disabled="isDisabled"
				></v-select>
			</div>
			<div class="column-format gap-3 mt-4 pt-4 text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<h3>Primary contact</h3>
				<div class="row-format gap-2">
					<v-text-field
						label="First name"
						v-model="businessRecord.contact.firstName"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="Last name"
						v-model="businessRecord.contact.lastName"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
				<v-text-field
					label="Title"
					v-model="businessRecord.contact.title"
					:rules="required"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
					:disabled="isDisabled"
				></v-text-field>
				<div class="row-format gap-2">
					<v-text-field
						label="Email"
						v-model="businessRecord.contact.email"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
					<v-text-field
						label="Phone"
						v-model="businessRecord.contact.phone"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="isDisabled"
					></v-text-field>
				</div>
			</div>

			<div class="column-format gap-3 mt-4 pt-4 text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<h3>UC Protect Branded Calling Profile</h3>
				<v-autocomplete
					v-if="brandedCallingProfiles.length"
					density="compact"
					hide-details
					variant="outlined"
					label="Branded calling profile"
					placeholder="Branded calling not enabled"
					class="mt-2"
					:items="brandedCallingProfiles"
					v-model="businessRecord.ucProtectBrandedCallingProfileId"
					item-value="id"
					item-title="label"
					persistent-placeholder
					clearable
					:disabled="isDisabled"
				></v-autocomplete>
			</div>

			<div class="column-format gap-3 mt-4 pt-4 text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<h3>Approved CNAM</h3>
				<div v-for="cnam in businessRecord.cnams" :key="cnam.id">
					<div class="row-format align-center">
						<v-text-field
							v-model="cnam.name"
							:rules="required"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							:disabled="isDisabled"
						></v-text-field>
						<p-icon class="pointer" color="gray_70" @click="confirmDeleteCnam(cnam)" :size="20" v-if="!isDisabled"
							>delete</p-icon
						>
					</div>
				</div>
				<v-btn variant="text" @click="addCnam()" v-if="!isDisabled">+Add CNAM</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from '@/components/ConfirmModal';
	import BusinessRecordService from '@/modules/watchTower/BusinessRecordService';
	import { DateTime } from 'luxon';
	import { v4 as uuid4 } from 'uuid';
	import BrandedCallingService from '@/modules/watchTower/BrandedCallingService';

	export default defineComponent({
		name: 'BusinessRecordDetail',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				businessRecord: null,
				brandedCallingProfileService: new BrandedCallingService(),
				businessRecordService: new BusinessRecordService(),
				required: [(v) => !!v || 'Field is required'],
				areaCodes: null,
				statusList: ['ACTIVE', 'DISABLED'],
				brandedCallingProfiles: [],
			};
		},

		mounted() {
			this.getBusinessRecord();
			this.getBrandedCallingProfiles();
		},

		beforeUnmount() {},

		methods: {
			async getBusinessRecord() {
				if (this.id) {
					let result = await this.businessRecordService.getBusinessRecord(this.workspaceId, this.id);
					this.businessRecord = result.data;
				} else {
					this.businessRecord = this.newBusinessRecord();
				}
			},

			async save() {
				if (!this.businessRecord.id) {
					let result = await this.businessRecordService.createBusinessRecord(this.workspaceId, this.businessRecord);
					this.$emit('result', result.data);
				} else {
					let result = await this.businessRecordService.updateBusinessRecord(
						this.workspaceId,
						this.businessRecord.id,
						this.businessRecord
					);
					this.$emit('result', result.data);
				}
			},

			addCnam: function () {
				this.businessRecord.cnams.push({
					id: uuid4(),
					name: null,
				});
			},

			confirmDeleteCnam: function (cnam) {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this CNAM?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.businessRecord.cnams.findIndex((c) => c.id === cnam.id);
						if (ix > -1) {
							this.businessRecord.cnams.splice(ix, 1);
						}
					}
				});
			},

			confirmDelete() {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this Business Record?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (this.businessRecord.id) {
							this.businessRecordService.deleteBusinessRecord(this.workspaceId, this.businessRecord.id);
							this.businessRecord.deleted = true;
							this.$emit('result', this.businessRecord);
						} else {
							this.$emit('result');
						}
					}
				});
			},

			newBusinessRecord() {
				return {
					id: null,
					resourceStatus: 'ACTIVE',
					dateCreated: DateTime.now().toISO(),
					dateUpdated: DateTime.now().toISO(),
					legalName: null,
					callCategory: 'Informational',
					callsPerMonth: 10000,
					ein: null,
					website: null,
					phone: null,
					address: {
						address1: null,
						address2: null,
						city: null,
						state: null,
						zip: null,
					},
					contact: {
						firstName: null,
						lastName: null,
						title: null,
						email: null,
						phone: null,
					},
					cnams: [],
				};
			},

			getBrandedCallingProfiles() {
				this.brandedCallingProfileService.getBrandedCallingProfileList(this.workspaceId).then((res) => {
					this.brandedCallingProfiles.replace(res.data);
				});
			},
		},

		watch: {
			areaCodes: function () {
				if (this.areaCodes) {
					let value = this.areaCodes.split(',');
					for (let i = 0; i < value.length; i++) {
						value[i] = value[i].trim();
					}
					this.ftcSan.areaCodes.replace(value);
				} else {
					this.ftcSan.areaCodes.splice(0);
				}
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			isDisabled: function () {
				return this.$store.state.user.userType !== 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss"></style>
