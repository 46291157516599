<template>
	<v-menu
			ref="menu"
			v-model="dateMenu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			:disabled="disabled"
			min-width="290px"
	>
		<template v-slot:activator="{ props }">
			<div v-on="on">
				<div v-if="minimal" v-bind="props" class="pointer">
					<div v-if="dateFormatted" :class="contentClass" style="white-space:nowrap">{{ dateFormatted }}</div>
					<v-icon v-else size="20" class="material-symbols-rounded">event</v-icon>
				</div>
				<div v-else-if="micro" v-bind="props" :class="`pointer ${microFont ? microFont : 'font-12'}`">
					<div v-if="dateFormatted" :class="contentClass" style="white-space:nowrap">{{ dateFormatted }}</div>
					<div v-else v-html="label"></div>
				</div>
				<v-text-field
					v-else-if="standard"
					v-bind="props"
					:value="dateFormatted"
					readonly
					:label="label"
					:disabled="disabled"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
					class="pointer"
				>
				</v-text-field>
				<v-text-field
					v-else
					v-bind="props"
					:value="dateFormatted"
					readonly
					:label="label"
					:disabled="disabled"
					hide-details
					persistent-placeholder
					class="pointer"
				>
				</v-text-field>
			</div>
		</template>
		<v-date-picker v-model="date" @update:modelValue="dateMenu=false"></v-date-picker>
	</v-menu>
</template>

<script>
import {defineComponent} from 'vue';
import {DateTime} from 'luxon';

export default defineComponent({
	name: 'DateSelector',

	props: ['label','disabled','modelValue','dateType','retType', 'minimal', 'micro', 'standard', 'microFont', 'contentClass'],

	components: {},

	data: function () {
		return {
			dateMenu: false,
			date: this.modelValue,
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {},

	watch: {
		date: function(){
			if (this.date && this.retType === 'ISO') {
				this.$emit('update:modelValue', this.date);
			}
			else if(this.date){
				this.$emit('update:modelValue', DateTime.fromISO(this.date).toISODate());
			}else {
				this.$emit('update:modelValue', null);
			}
		}
	},

	computed: {
		dateFormatted: function () {
			if (this.date) {
				if(this.dateType === "short") {
					return DateTime.fromISO(this.date).toLocaleString(DateTime.DATE_SHORT);
				}
				return DateTime.fromISO(this.date).toLocaleString(DateTime.DATE_MED);
			} else {
				return '';
			}
		},
	},
});
</script>

<style scoped lang="scss"></style>
