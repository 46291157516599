<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="pb-8">
				<module-header name="My Profile" :workspace-selector="false"> </module-header>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" md="8">
				<v-card>
					<v-form>
						<v-container class="pa-6">
							<v-row>
								<v-col cols="12" md="6">
									<v-text-field
											v-model="user.firstName"
											type="text"
											label="First Name"
											persistent-placeholder
											autofocus
											hide-details
											variant="outlined"
											density="compact"
									/>
								</v-col>

								<v-col cols="12" md="6">
									<v-text-field
											v-model="user.lastName"
											type="text"
											label="Last Name"
											persistent-placeholder
											autofocus
											hide-details
											variant="outlined"
											density="compact"
									/>
								</v-col>

								<v-col cols="12">
									<v-text-field
										v-model="user.email"
										type="text"
										label="Email Address"
										persistent-placeholder
										autofocus
										hide-details
										density="compact"
										variant="outlined"
									/>
								</v-col>

								<v-col cols="12">
									<maz-phone-number-input
										:translations="{ phoneInput: { placeholder: 'Phone', example: '' } }"
										:only-countries="['US', 'CA', 'EC']"
										size="sm"
										v-model="user.phone"
									>
									</maz-phone-number-input>
									<div class="font-12 font-italic text-left py-2">By providing your phone number, you consent to receive text messages for authentication purposes, including One-Time Passwords (OTP) and security notifications. Message and data rates may apply. Your phone number will not be used for marketing or shared without your consent. If you wish to disable text message-based 2FA, you can remove your phone number at any time and select an alternative 2FA method, such as an authenticator app, in your account settings.</div>
								</v-col>

								<v-col cols="12">
									<v-text-field
										v-model="user.address"
										type="text"
										label="Address"
										persistent-placeholder
										autofocus
										hide-details
										variant="outlined"
										density="compact"
									/>
								</v-col>


								<v-col cols="12" md="4">
									<v-text-field
										v-model="user.city"
										label="City"
										persistent-placeholder
										autofocus
										hide-details
										variant="outlined"
										density="compact"
									/>
								</v-col>

								<v-col cols="12" md="4">
									<v-text-field
										v-model="user.state"
										label="State"
										persistent-placeholder
										autofocus
										hide-details
										variant="outlined"
										density="compact"
									/>
								</v-col>

								<v-col cols="12" md="4">
									<v-text-field
										v-model="user.postalCode"
										type="text"
										label="Postal Code"
										persistent-placeholder
										autofocus
										hide-details
										variant="outlined"
										density="compact"
									/>
								</v-col>

								<v-col cols="12">
									<v-file-input
										accept="image/png, image/jpeg, image/svg"
										v-model="file"
										persistent-placeholder
										label="Profile picture"
										hide-details
										variant="outlined"
										density="compact"
									>
									</v-file-input>
								</v-col>
								<v-col cols="12">
									<v-btn block color="secondary" variant="flat" @click="updateProfile">Update Profile</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<v-card class="v-card-profile">
					<v-avatar color="grey" size="150" rounded="50">
						<v-img cover :src="user.profilePictureUrl || '/images/default.jpg'"></v-img>
					</v-avatar>
					<v-card-text class="text-center">
						<h4 class="display-2 font-weight-light mb-3 black--text">{{ user.firstName }} {{ user.lastName }}</h4>
						<h6 class="display-1 mb-1 grey--text">
							{{ user.role }}
						</h6>
						<h6 class="display-1 mb-1 grey--text">
							{{ user.email }}
						</h6>
						<h6 class="display-1 mb-1 grey--text">
							{{ user.phone }}
						</h6>

						<p class="font-weight-light grey--text">
							{{ user.linkedin }}
						</p>
					</v-card-text>
				</v-card>
				<div class="column-format centered mt-8" v-if="user.mfaSet">
					<span class="material-symbols-outlined" style="font-size: 100px; color: rgb(var(--v-theme-primary))">passkey</span>
					<div class="brand-medium">Two Factor Device Active</div>
				</div>
				<div class="column-format centered mt-8 pointer" v-else @click="enableMfa()">
					<span class="material-symbols-outlined" style="font-size: 100px; color: rgb(var(--v-theme-secondary))">lock_open</span>
					<div class="brand-medium">Enable Two Factor Device</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
	import ProfileService from './ProfileService';
	import EnableMFA from "@/modules/profile/EnableMFA";

	export default defineComponent({
		name: 'Profile',

		props: [],

		components: { ModuleHeader, MazPhoneNumberInput },

		data: function () {
			return {
				user: {},
				file: [],
				countries: [],
				states: [],
				cities: [],
				profileService: new ProfileService(),
			};
		},

		async mounted() {
			await this.getProfile();
		},

		beforeUnmount() {},

		methods: {
			enableMfa(){
				this.$store.state.globalModalController.openModal(EnableMFA,{}).then((res) => {
					if(res){
						this.getProfile();
					}
				})
			},

			async getProfile() {
				try {
					this.user = (await this.profileService.getProfile()).data;
				} catch (error) {
					this.$store.commit('error', error.response.data.message);
				}
			},

			async updateProfile() {
				let file = this.file.length ? this.file[0] : null;

				try {
					await this.profileService.updateProfile(this.user, file);
					this.$store.commit('success', 'Profile updated successfully.');
				} catch (error) {
					this.$store.commit('error', error.response.data.message);
				}
			},
		},

		computed: {
		},
	});
</script>

<style scoped lang="scss">
	.upload-btn {
		position: absolute !important;
		z-index: 999;
		top: 121px;
		color: cadetblue;
		background: blueviolet;
		background: rgb(125, 198, 163);
		background: linear-gradient(50deg, rgba(125, 198, 163, 1) 0%, rgba(35, 216, 227, 1) 72%);
	}

	.avatar-shadow {
		box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
		-webkit-box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
		-moz-box-shadow: 0px 0px 10px 0px rgba(50, 12, 112, 0.75);
	}
</style>
